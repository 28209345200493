.top-area{
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
}

h2{
    margin-top: 25px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 5px;
    color: #666666
}


.orderinfo-type{
    display: inline-block;
    background-color: red;
    color: white;
    border-radius: 5px;
    padding: 5px 8px;
    font-size: 1.15rem;
}

.top-area > *{
    padding: 10px 0px;
}

.top-area > *:not(:last-child){
    border-bottom: 1px solid white;
}