@import '~antd/dist/antd.css';

body{
    margin: 0px !important;
}

.ant-btn{
    border-radius: 4px;
}


.full-table{
    width: 100%
}

.full-table td, .full-table th{
    padding: 10px !important;
}

.border-table{
    border-left: 1px solid #666666;
    border-top: 1px solid #666666;
}

.border-table td, .border-table th{
    border-right: 1px solid #666666;
    border-bottom: 1px solid #666666;
}

.right{
    text-align: right;
}