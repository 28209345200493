.desc{
    font-size: 16px;
}

.qr{
    float: left;
}

.qr img{
    height: 200px;
}

.qr > div{
    height: 200px;
}

.icon{
    width: 40px;
}