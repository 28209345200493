.order-header{
    background-color: #f0f0f0;
}

.order-list{
    width: 100%;
    line-height: 1.5;
    border-left: 1px solid #666666;
    border-top: 1px solid #666666;
}

.order-list th{
    background-color: #fcfcfc;
}

.order-list td, .order-list th{
    padding: 1rem !important;
    border-right: 1px solid #666666;
    border-bottom: 1px solid #666666;
}

.order-name{
    color: rgb(14, 95, 182);
    font-size: 1.2rem;
    margin-bottom: 5px;
}

.filter{
    padding: 0px 10px
}

.filter .ant-form-item-control{
    margin-right: 10px;
}